import styled from '@emotion/styled';
import DelogueLogoName from 'img/delogue-logo-name.svg';
import DelogueLogo from 'img/delogue-logo.svg';
import { UserDetails } from './UserDetails';
import { OldSiteLink } from './OldSiteLink';
import { Image } from 'components';

export const Topbar = () => {
  return (
    <Container>
      <LeftBar>
        <Image src={DelogueLogo} alt="Delogue logo" width="auto" />
      </LeftBar>
      <Image src={DelogueLogoName} alt="Delogue logo name" width="auto" />
      <RightBar>
        <OldSiteLink />
        <UserDetails />
      </RightBar>
    </Container>
  );
};

const Container = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  background-color: ${p => p.theme.palette.bg.primary};
  border-bottom: 1px solid ${p => p.theme.palette.border.primarySubtle};
  padding: 16px 24px;
`;

const RightBar = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: flex-end;
  align-items: center;
`;

const LeftBar = styled.div`
  flex-grow: 1;
  flex-basis: 0;
`;
