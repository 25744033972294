import { useUser } from 'features/auth/hooks';
import styled from '@emotion/styled';
import { LoginContext } from 'features/auth/types';
import { Button, BaseButton } from 'components';
import { CenterContainer } from './CenterContainer';
import { Trans } from '@lingui/macro';
import { SignIn } from '@phosphor-icons/react';

type SwitchCompanyProps = {
  onSelectCompany: (loginContext: LoginContext) => void;
  onLogout: () => void;
};

export const SwitchCompanyForm = ({ onSelectCompany, onLogout }: SwitchCompanyProps) => {
  const { data } = useUser({});

  return (
    <CenterContainer welcomeText={<Trans>... Welcome back</Trans>}>
      <List>
        <div>
          <Header>
            <Trans>Select Company</Trans>
          </Header>
          {data?.user?.loginContexts?.map((loginContext: LoginContext) => (
            <BaseButton key={loginContext?.id} onClick={() => onSelectCompany(loginContext)}>
              <SignIn /> {loginContext?.organizationName}
              {loginContext?.organizationType === 2 ? ' (Supplier)' : ''}
            </BaseButton>
          ))}
        </div>
        <Logout>
          <Button color="primary" variant="contained" onClick={() => onLogout()}>
            Log out
          </Button>
        </Logout>
      </List>
    </CenterContainer>
  );
};

const Logout = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const List = styled.div`
  margin: 16px 24px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  button {
    padding: 8px 12px;
    border-radius: 4px;
    margin-bottom: 8px;
    :hover {
      background-color: ${p => p.theme.palette.bg.secondary};
    }
  }
`;

const Header = styled.h1`
  margin-bottom: 16px;
  color: ${p => p.theme.palette.text.primary};
  font-weight: 600;
  font-size: 18px;
`;
