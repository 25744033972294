import { useRef } from 'react';
import { toast, Id, ToastOptions } from 'react-toastify';
import { Toast } from 'features/notifications';
import type { Variant, ToastProps, CombinedTypes } from 'features/notifications/types';

const splitProps = (
  props: CombinedTypes,
  variant: Variant = 'info'
): [ToastProps, ToastOptions<ToastProps>] => {
  const { id, message, header, actions, onClose, ...rest } = props;
  const toastId = `${id}-${Math.random()}`;
  return [
    { id: toastId, message, header, actions, variant, onClose },
    { ...rest, toastId, onClose: () => onClose && onClose('', toastId) },
  ];
};

const base = (props: CombinedTypes): Id => {
  const [toastProps, toastOptions] = splitProps(props);
  return toast<ToastProps>(Toast(toastProps), toastOptions);
};

const success = (props: CombinedTypes): Id => {
  const [toastProps, toastOptions] = splitProps(props, 'success');
  return toast.success<ToastProps>(Toast(toastProps), toastOptions);
};

const error = (props: CombinedTypes): Id => {
  const [toastProps, toastOptions] = splitProps(props, 'error');
  return toast.error<ToastProps>(Toast(toastProps), toastOptions);
};

const warning = (props: CombinedTypes): Id => {
  const [toastProps, toastOptions] = splitProps(props, 'warning');
  return toast.warning<ToastProps>(Toast(toastProps), toastOptions);
};

const info = (props: CombinedTypes): Id => {
  const [toastProps, toastOptions] = splitProps(props, 'info');
  return toast.info<ToastProps>(Toast(toastProps), toastOptions);
};

export const useNotification = () => {
  const ids = useRef<Id[]>([]);

  const onClose = (btn: string, id: string | number, props: CombinedTypes) => {
    if (ids.current.indexOf(id) > -1) {
      if (btn) {
        toast.dismiss(id);
      }
      ids.current = ids.current.filter(toastId => toastId !== id);
      if (props.onClose) {
        props.onClose(btn, id);
      }
    }
  };

  return {
    notify: {
      base: (props: CombinedTypes) => {
        const toastId = base({
          ...props,
          onClose: (btn, id) => onClose(btn, id, props),
        });
        ids.current.push(toastId);
      },
      success: (props: CombinedTypes) => {
        const toastId = success({
          ...props,
          onClose: (btn, id) => onClose(btn, id, props),
        });
        ids.current.push(toastId);
      },
      error: (props: CombinedTypes) => {
        const toastId = error({
          ...props,
          onClose: (btn, id) => onClose(btn, id, props),
        });
        ids.current.push(toastId);
      },
      warning: (props: CombinedTypes) => {
        const toastId = warning({
          ...props,
          onClose: (btn, id) => onClose(btn, id, props),
        });
        ids.current.push(toastId);
      },
      info: (props: CombinedTypes) => {
        const toastId = info({
          ...props,
          onClose: (btn, id) => onClose(btn, id, props),
        });
        ids.current.push(toastId);
      },
    },
    dismissNotification: toast.dismiss,
  };
};
