import { useState } from 'react';
import { t } from '@lingui/macro';
import styled from '@emotion/styled';
import { Autocomplete, createFilterOptions, type FilterOptionsState } from '@mui/material';
import { TextInput } from './TextInput';
import type { ComboBoxProps, ComboBoxOption } from './types';
import { useGet } from 'lib/api';
import { stringParse } from 'utils';
import { debounce } from 'lodash';
import { lighten } from '@mui/system';
export const ComboBox = ({
  id,
  sx,
  options = [],
  label,
  placeholder,
  clearOnBlur = true,
  openText = t`Open`,
  loading = false,
  fullWidth = false,
  loadingText = t`Loading...`,
  noOptionsText = t`No options`,
  inputVariant = 'unstyled',
  freeSolo = true,
  InputProps,
  error,
  value,
  onBlur,
  onChange,
  onClose,
  onInputChange,
  open,
  api = null,
  optionsLimit,
  onKeyDown,
  groupBy = false,
}: ComboBoxProps) => {
  const [search, setSearch] = useState('');
  // get options from an api if provided
  const { data } = useGet(
    api?.search ? stringParse(api?.url, [search]) : api?.url || '',
    api?.search ? [api?.key, search] : api?.key || '',
    api?.revalidate !== false,
    { enabled: api?.search ? Boolean(search) : !!api }
  );
  const selectOptions = data && api ? api.responseTransform(data) : options;
  const inputChangeApi = debounce((newInputValue: string) => {
    setSearch(newInputValue);
  }, 250);

  // limit amount of options shown
  const defaultFilterOptions = createFilterOptions<ComboBoxOption>();
  const filterOptions = (opts: ComboBoxOption[], state: FilterOptionsState<ComboBoxOption>) => {
    return defaultFilterOptions(opts, state).slice(0, optionsLimit);
  };

  return (
    <Autocomplete
      selectOnFocus
      value={value}
      clearOnBlur={clearOnBlur}
      freeSolo={freeSolo}
      fullWidth={fullWidth}
      openText={openText}
      id={id}
      open={open}
      loading={loading}
      loadingText={loadingText}
      componentsProps={{
        paper: {
          sx: {
            minWidth: 250,
          },
        },
      }}
      noOptionsText={noOptionsText}
      options={selectOptions}
      groupBy={
        groupBy
          ? option => (option as ComboBoxOption).groupByHeader?.toUpperCase() || ''
          : undefined
      }
      renderGroup={params =>
        groupBy ? (
          <li key={params.key}>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        ) : (
          ''
        )
      }
      sx={sx}
      onBlur={onBlur}
      onChange={onChange}
      onClose={onClose}
      getOptionDisabled={({ disabled = false }) => disabled}
      getOptionLabel={option => {
        if (typeof option === 'string') return option;
        if (option?.handleClick) return '';
        return option?.label;
      }}
      filterOptions={optionsLimit ? filterOptions : undefined}
      onInputChange={(event: React.SyntheticEvent, newInputValue: string, reason: string) => {
        if (onInputChange) onInputChange(event, newInputValue, reason);
        if (api?.search) inputChangeApi(newInputValue);
      }}
      renderOption={(props, option) => {
        return (
          <Li {...props}>
            {option.CustomElement ? (
              <option.CustomElement
                label={option.label}
                handleClick={option.handleClick}
                {...option.customProps}
              />
            ) : (
              <span onClick={option.handleClick}>{option.label}</span>
            )}
          </Li>
        );
      }}
      renderInput={params => {
        return (
          <TextInput
            {...params}
            error={error}
            color={error ? 'error' : 'primary'}
            placeholder={loading ? loadingText : placeholder}
            label={label}
            variant={inputVariant}
            InputProps={{ ...params.InputProps, ...InputProps }}
            autoFocus={Boolean(open)}
            onKeyDown={onKeyDown}
          />
        );
      }}
    />
  );
};

const Li = styled.li`
  font-size: 14px;
`;
const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor: lighten(theme.palette.primary.light, 0.85),
}));

const GroupItems = styled('ul')({
  padding: 0,
});
