import styled from '@emotion/styled';
import { Image } from 'components';
import DelogueLogoBig from 'img/delogue-logo-big.svg';

type WelcomeProps = {
  children: React.ReactNode;
};

export const Welcome = ({ children }: WelcomeProps) => {
  return (
    <WelcomeCon>
      <Image src={DelogueLogoBig} alt="Delogue logo name" width="auto" />
      <Text>{children}</Text>
    </WelcomeCon>
  );
};

const Text = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${p => p.theme.palette.text.brandPrimary};
  font-size: 16px;
`;

const WelcomeCon = styled.div`
  width: 50%;
  min-height: 350px;
  background-color: ${p => p.theme.palette.fg.brandPrimary};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
