import { useState } from 'react';
import { useKeyPress } from 'hooks/useKeyPress';
import styled from '@emotion/styled';
import { TextInput, Checkbox, BaseButton, Button } from 'components';
import { Trans, t } from '@lingui/macro';
import { EnvelopeSimple, Lock, Eye, EyeSlash } from '@phosphor-icons/react';
import { CenterContainer } from './CenterContainer';
import { DOMAIN } from 'config';

type LoginFormProps = {
  onSubmit: (user: string, password: string, remember: boolean) => void;
};

export const LoginForm = ({ onSubmit }: LoginFormProps) => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState(false);

  useKeyPress('Enter', () => {
    onSubmit(user, password, remember);
  });

  return (
    <CenterContainer welcomeText={<Trans>... Welcome back</Trans>}>
      <Form>
        <Header>
          <Trans>Log in to your account</Trans>
        </Header>
        <TextInput
          placeholder={t`Email`}
          InputProps={{ startAdornment: <EnvelopeSimple /> }}
          variant="outlined"
          value={user}
          onChange={e => {
            setUser(e.target.value);
          }}
        />
        <TextInput
          placeholder={t`Password`}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            startAdornment: <Lock />,
            endAdornment: (
              <BaseButton data-testid="eye" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <EyeSlash /> : <Eye />}
              </BaseButton>
            ),
          }}
          variant="outlined"
          value={password}
          onChange={e => {
            setPassword(e.target.value);
          }}
        />
        <RememberForgot>
          <Remember>
            <Checkbox
              checked={remember}
              label={t`Remember me`}
              onChange={() => setRemember(!remember)}
            />
          </Remember>
          <a href={`${DOMAIN}/forgot`}>
            <Forgot>
              <Trans>Forgot password?</Trans>
            </Forgot>
          </a>
        </RememberForgot>
      </Form>
      <SignIn>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSubmit(user, password, remember)}
        >
          <Trans>Sign in</Trans>
        </Button>
      </SignIn>
    </CenterContainer>
  );
};

const Header = styled.h1`
  margin-bottom: 16px;
  color: ${p => p.theme.palette.text.primary};
  font-weight: 600;
  font-size: 18px;
`;

const Form = styled.div`
  margin: 16px 24px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  svg {
    fill: ${p => p.theme.palette.fg.quinary};
  }
`;

const RememberForgot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const Remember = styled.span`
  label {
    color: ${p => p.theme.palette.text.secondary};
    font-size: 12px;
    font-weight: 400;
  }
  .MuiCheckbox-root {
    padding: 0;
    padding-right: 4px;
  }
`;

const Forgot = styled.span`
  font-size: 12px;
  color: ${p => p.theme.palette.text.link};
  font-weight: 600;
`;

const SignIn = styled.div`
  margin: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
