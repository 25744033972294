export const roles = [
  {
    role: 'Company User',
    access: [
      {
        verb: 'write',
        scopes: [
          'taskboard',
          'dashboard',
          'style',
          'item',
          'report',
          'analytics',
          'marketing',
          'orders',
          'compliance',
          'example',
          'login',
        ],
      },
      {
        verb: 'read',
        scopes: [
          'taskboard',
          'dashboard',
          'style',
          'item',
          'report',
          'analytics',
          'marketing',
          'orders',
          'compliance',
          'example',
          'login',
        ],
      },
    ],
  },
  {
    role: 'Company Admin',
    access: [
      {
        verb: 'write',
        scopes: ['admin'],
      },
      {
        verb: 'read',
        scopes: ['admin'],
      },
    ],
  },
  {
    role: 'Test User',
    access: [
      {
        verb: 'write',
        scopes: ['login'],
      },
      {
        verb: 'read',
        scopes: ['taskboard', 'dashboard', 'style/workflow', 'storybook', 'test', 'login'],
      },
    ],
  },
  {
    role: 'Test Admin',
    access: [
      {
        verb: 'write',
        scopes: [
          'taskboard',
          'dashboard',
          'admin',
          'style',
          'style/workflow',
          'storybook',
          'test',
          'login',
        ],
      },
      {
        verb: 'read',
        scopes: [
          'taskboard',
          'dashboard',
          'admin',
          'style',
          'style/workflow',
          'storybook',
          'test',
          'login',
        ],
      },
    ],
  },

  {
    role: 'Supplier Admin',
    access: [
      {
        verb: 'read',
        scopes: ['taskboard', 'dashboard', 'admin', 'style', 'style/workflow', 'storybook', 'test'],
      },
    ],
  },
];
