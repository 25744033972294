import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { forEach } from 'lodash';
import { useStoreState, useStoreActions } from 'stores/global';

export const useUrlState = <T>(
  initialState: T,
  persist: boolean = false
): [T, (value: T | null) => void] => {
  const { persistedState } = useStoreState(state => state.urlState);
  const { setPersistedState } = useStoreActions(state => state.urlState);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (persist && persistedState != null) {
      setSearchParams(currentState => {
        const current = getEntries(currentState.entries());
        return { ...current, ...formatState(initialState, persistedState) };
      });
    }
  }, []);

  const setUrlState = (state: T | null) => {
    setSearchParams(currentState => {
      if (state) {
        const current = getEntries(currentState.entries());
        const values: Record<string, string> = {};
        forEach(state, (value, key) => {
          if (value != null && value !== false) {
            values[key] = stringify(value) as string;
          } else if (current[key] != null) {
            delete current[key];
          }
        });
        if (persist) {
          setPersistedState(values);
        }
        return { ...current, ...values };
      }
      return currentState;
    });
  };

  const urlState = getEntries(searchParams.entries(), true) as T;

  return [urlState, setUrlState];
};

const formatState = <T>(initialState: T, persistedState: Record<string, string>) => {
  if (initialState == null) {
    return undefined;
  }
  const state: Record<string, string> = {};
  forEach(initialState, (value, key) => {
    if (persistedState[key] != null) {
      state[key] = persistedState[key];
    }
  });
  return state;
};

const getEntries = (entries: IterableIterator<[string, string]>, shouldParse: boolean = false) => {
  const object: Record<string, string> = {};
  for (const entry of entries) {
    if (shouldParse) {
      object[entry[0]] = parse(entry[1]);
    } else {
      object[entry[0]] = entry[1];
    }
  }
  return object;
};

const stringify = <T>(value: T): string | null => {
  if (value == null) {
    return null;
  }
  if (typeof value === 'string') {
    return value;
  }
  if (typeof value === 'number') {
    return `n|${value.toString()}`;
  }
  if (typeof value === 'boolean') {
    if (value === false) {
      return null;
    }
    return '';
  }
  if (typeof value === 'object') {
    return `o|${JSON.stringify(value)}`;
  }
  return null;
};

const parse = (value: string | null) => {
  if (value == null) {
    return null;
  }
  if (value.startsWith('n|')) {
    return parseFloat(value.slice(2));
  }
  if (value === '') {
    return true;
  }
  if (value.startsWith('o|')) {
    try {
      return JSON.parse(value.slice(2));
    } catch (e) {
      return null;
    }
  }
  return value;
};
