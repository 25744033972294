import { useRegisterSW } from 'virtual:pwa-register/react';
import { Prompt } from './Prompt';

export const ReloadPrompt = () => {
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(_, r) {
      if (r) {
        setInterval(() => {
          r.update();
        }, 1000 * 60);
      }
    },
  });

  const close = () => {
    setOfflineReady(false);
    setNeedRefresh(false);
  };

  return (
    <Prompt
      offlineReady={offlineReady}
      needRefresh={needRefresh}
      handleClose={close}
      handleUpdate={updateServiceWorker}
    />
  );
};
