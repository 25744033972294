import styled from '@emotion/styled';
import { Topbar } from 'components/topbar';
import { Sidebar } from 'components/sidebar';

export const Layout = ({
  children,
  noPadding = false,
}: {
  children: React.ReactNode;
  noPadding: boolean;
}) => {
  return (
    <LayoutWrapper noPadding={noPadding}>
      <Topbar />
      <Sidebar>
        <main>
          <Page noPadding={noPadding}>{children}</Page>
        </main>
      </Sidebar>
    </LayoutWrapper>
  );
};

const Page = styled.div<{ noPadding: boolean }>`
  padding-left: ${p => (p.noPadding ? 0 : '16px')};
  padding-right: ${p => (p.noPadding ? 0 : '16px')};
  background-color: ${p => p.theme.palette.bg.primary};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const LayoutWrapper = styled.div<{ noPadding: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: #fbf9f7;
    padding: ${p => (p.noPadding ? 0 : '16px')};
    padding-bottom: 0;
    height: calc(100vh - 72px);
    overflow-x: hidden;
    overflow-y: auto;
  }
`;
