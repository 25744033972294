import { IconButton, Drawer as MuiDrawer, styled, Box } from '@mui/material';
import type { DrawerProps as MuiDrawerProps, SxProps } from '@mui/material';
import { X, PencilSimple, CheckCircle, XCircle } from '@phosphor-icons/react';
import { TextInput } from 'components/inputs';
import { useMemo, useState } from 'react';
import { colors } from 'styles/theme/colors';
export type DrawerProps = Pick<
  MuiDrawerProps,
  'hideBackdrop' | 'open' | 'classes' | 'sx' | 'anchor'
> & {
  onClose?: (
    event: React.SyntheticEvent,
    reason: 'backdropClick' | 'escapeKeyDown' | 'closeClick'
  ) => void;
  headerSlot?: React.ReactNode;
  children: React.ReactNode;
  footerSlot?: React.ReactNode;
  headerSx?: SxProps;
  bodySx?: SxProps;
  footerSx?: SxProps;
  variant?: 'temporary' | 'persistent' | 'permanent';
  handleSaveClick?: (headerName: string) => void;
};

export const Drawer = ({
  onClose,
  hideBackdrop,
  open,
  anchor = 'right',
  headerSlot,
  headerSx,
  footerSlot,
  footerSx,
  children,
  bodySx,
  sx: sxProp,
  variant,
  handleSaveClick,
}: DrawerProps) => {
  const sx = useMemo(
    () => ({
      ...sxProp,
      width: 'clamp(280px, 50vw, 680px)',
      background: colors.beige[300],
      padding: '3rem',
    }),
    [sxProp]
  );
  const [headerNameEditMode, setHeaderNameEditMode] = useState(false);
  const [tempName, setTempName] = useState(headerSlot);
  const handleCancelClick = () => {
    setHeaderNameEditMode(false);
    setTempName(headerSlot);
  };
  return (
    <MuiDrawer
      data-testid="drawer"
      onClose={onClose}
      hideBackdrop={hideBackdrop}
      open={open}
      keepMounted
      PaperProps={{ sx }}
      anchor={anchor}
      variant={variant}
    >
      <Header sx={headerSx}>
        {headerNameEditMode ? (
          <Row>
            <Column>
              <TextInput
                aria-label="note-text"
                onChange={e => setTempName(e.target.value)}
                value={tempName?.toString()}
                autoFocus
                fullWidth
              />
            </Column>
            <Column>
              <XCircle
                onClick={handleCancelClick}
                color={colors.grey[300]}
                aria-label="clear-text"
                size={16}
                style={{ height: '24px', width: '24px', cursor: 'pointer' }}
              />
              <CheckCircle
                aria-label="save-note"
                size={16}
                style={{ height: '24px', width: '24px', cursor: 'pointer' }}
                color={colors.success[500]}
                onClick={() => {
                  handleSaveClick && handleSaveClick(tempName?.toString() || '');
                  setHeaderNameEditMode(false);
                }}
              />
            </Column>
          </Row>
        ) : (
          <Row>
            <HeaderText as="h6">{headerSlot}</HeaderText>
            {handleSaveClick && (
              <Column>
                <PencilSimple
                  size={24}
                  onClick={e => {
                    setTempName(headerSlot);
                    setHeaderNameEditMode(true);
                  }}
                />
              </Column>
            )}
          </Row>
        )}
        <IconButton
          data-testid="drawer-close-button"
          onClick={e => onClose && onClose(e, 'closeClick')}
        >
          <X />
        </IconButton>
      </Header>
      <Body sx={bodySx}>{children}</Body>
      <Footer sx={footerSx}>{footerSlot}</Footer>
    </MuiDrawer>
  );
};

const Header = styled('header')({
  display: 'flex',
  justifyContent: 'space-between',
});

const Footer = styled('footer')({
  width: '100%',
});

const Body = styled('section')({
  height: '100%',
});

const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 4px;
  padding: 4px;
`;

const HeaderText = styled(Column)`
  color: ${p => p.theme.palette.text.primary};
  font-weight: 700;
  font-size: ${p => p.theme.typography.smallTitle.fontSize};
`;
