import { LoginContext, User, TokenData } from '../types';
import Cookies from 'js-cookie';
import { IS_DEV } from 'config';
import { omit } from 'lodash';

export const saveUserDataCookies = (userData: User, remember: boolean = false) => {
  const userDataNoBrands: User = {
    ...userData,
    loginContexts: userData.loginContexts.map((loginContext: LoginContext) =>
      omit(loginContext, 'brands')
    ),
  };
  Cookies.set('auth_userdata', JSON.stringify(userDataNoBrands), {
    expires: remember ? 7 : undefined,
    secure: !IS_DEV,
  });
};

export const saveTokenCookies = (
  tokenData: TokenData,
  loginContext: LoginContext,
  remember: boolean = false
) => {
  const expires = remember ? 7 : undefined;
  Cookies.set('auth_access_token', tokenData?.access_token || '', { expires, secure: !IS_DEV });
  Cookies.set('auth_token_type', tokenData?.token_type || '', { expires, secure: !IS_DEV });
  Cookies.set('auth_expires_in', String(tokenData?.expires_in) || '', {
    expires,
    secure: !IS_DEV,
  });
  Cookies.set('auth_login_context', JSON.stringify(omit(loginContext, 'brands')) || '', {
    expires,
    secure: !IS_DEV,
  });
};

export const deleteUserCookies = () => {
  Cookies.remove('auth_userdata');
  deleteTokenCookies();
};

export const deleteTokenCookies = () => {
  Cookies.remove('auth_access_token');
  Cookies.remove('auth_token_type');
  Cookies.remove('auth_expires_in');
  Cookies.remove('auth_login_context');
};

export const getUserCookies = () => {
  const userDataJson = Cookies.get('auth_userdata');
  const userData: User = userDataJson ? JSON.parse(userDataJson) : null;
  const accessToken = Cookies.get('auth_access_token');
  const tokenType = Cookies.get('auth_token_type');
  const expiresIn = Cookies.get('auth_expires_in');
  const loginContextJson = Cookies.get('auth_login_context');
  const loginContext: LoginContext = loginContextJson ? JSON.parse(loginContextJson) : null;
  return { userData, accessToken, tokenType, expiresIn, loginContext };
};
